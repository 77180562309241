var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Customer Reviews "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAllReviews,"search":_vm.search},scopedSlots:_vm._u([{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" Description ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Review Description ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('p',[_vm._v(_vm._s(item.review))])])],1)],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.reply != null)?_c('p',{staticClass:"mb-0"},[_c('v-chip',{staticClass:"scondary-color mr-4",attrs:{"small":""}},[_vm._v("Reply:")]),_vm._v(_vm._s(item.reply)+" ")],1):_c('v-btn',_vm._g({attrs:{"color":"success","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"14"}},[_vm._v("mdi-comment-arrow-left")]),_vm._v(" Reply ")],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Reply")])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"rules":_vm.required,"solo":"","name":"input-7-4","label":"Write a reply"},model:{value:(_vm.editedItem.reply),callback:function ($$v) {_vm.$set(_vm.editedItem, "reply", $$v)},expression:"editedItem.reply"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-color",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"secondary-color",attrs:{"text":""},on:{"click":function($event){return _vm.reply()}}},[_vm._v("Submit ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }