<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title
        >Customer Reviews
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="getAllReviews" :search="search">
        <template v-slot:item.review="{ item }">
          <v-dialog :id="'descriptionDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                Description
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Review Description </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-2">
                <p>{{ item.review }}</p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.created="{ item }">{{
          item.created_at | formatDate()
        }}</template>
        <template v-slot:item.action="{ item }">
          <v-dialog :retain-focus="false" v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <p v-if="item.reply != null" class="mb-0">
                <v-chip small class="scondary-color mr-4">Reply:</v-chip
                >{{ item.reply }}
              </p>
              <v-btn
                v-else
                color="success"
                small
                dark
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon size="14" class="pr-2">mdi-comment-arrow-left</v-icon>
                Reply
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span>Reply</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-textarea
                    :rules="required"
                    solo
                    name="input-7-4"
                    v-model="editedItem.reply"
                    label="Write a reply"
                  >
                  </v-textarea>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary-color" text @click="close">
                  Cancel
                </v-btn>
                <v-btn class="secondary-color" text @click="reply()"
                  >Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  props: ["description"],

  data() {
    return {
      editedIndex: -1,
      search: "",
      valid: true,
      dialog: false,
      defaultItem: {},
      editedItem: {
        id: null,
        reply: null,
      },
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Product Name",
          sortable: false,
          value: "product.name",
        },
        { text: "Rating", value: "rating" },
        { text: "Description", value: "review" },
        { text: "Date", value: "created" },
        { text: "Action", value: "action" },
      ],
      required: [(v) => !!v || "Reply is required"],
    };
  },
  filters: {
    formatDate(val) {
      return moment(String(val)).fromNow();
    },
  },
  computed: {
    ...mapState({
      reviews: (state) => state.reviews,
    }),

    getAllReviews() {
      return this.reviews.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.user,
        product_name: item.product,
        reply: item.reply,
      }));
    },
  },
  methods: {
    ...mapActions(["replyToReview"]),

    reply() {
      if (this.$refs.form.validate()) {
        let temp = this.reviews.filter(
          (review) => review.id === this.editedItem.id
        )[0];
        Object.assign(this.reviews[this.editedIndex], this.editedItem);
        this.replyToReview(this.editedItem)
          .then((response) => {
            this.$store.commit("setReviews", this.reviews);
            console.log(response.status);
          })
          .catch((error) => {
            Object.assign(this.reviews[this.editedIndex], temp);
            console.error(error);
          });
        this.dialog = false;
      }
    },
    editItem(item) {
      let temp = this.reviews.filter((review) => review.id === item.id)[0];
      this.editedIndex = this.reviews.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style></style>
